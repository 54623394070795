
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TestDetailRoutingModule } from './test-detail-routing.module';
import { TestDetailComponent } from './test-detail.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [TestDetailComponent],
  imports: [
    CommonModule,
    TestDetailRoutingModule,
    MDBBootstrapModule.forRoot(),
    FormsModule,
    NgxSpinnerModule,
    SharedModule,

  ]
}) 
export class TestDetailModule { }

<div class="breadcrumb_container">
    <div class="container">
     <ol class="breadcrumb">
         <li class="breadcrumb-item"><a href="/labtest" class="default-color">Health Package</a></li>
         <li class="breadcrumb-item active">Cart </li>
     </ol>
   </div>
</div>
<section class="mt-5">
    <div class="container">
        <div class="row" *ngFor="let item of Labtest;let i = index;">
            <div class="col-12 col-md-8" >
                <h5 class="mb-4">{{item.name}}  </h5>
                <h6 class="mb-3" *ngIf="item.prerequisite">Pre-Test Information</h6>
                <p>{{item.prerequisite}}</p>
                
                <!-- <div class="aboutTest">
                    <div class="accordion accordion-flush" id="accordionParent">
                        <div class="accordion-item" *ngFor="let itemPara of LabTestParameter;let j = index;"  >
                          <h2 class="accordion-header" id="flush-headingOne">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#flush-collapse'+j" aria-expanded="false" aria-controls="'flush-collapse'+j">
                              Test Parameters #{{j+1}}
                            </button>
                          </h2>
                          <div id="flush-collapse{{j}}" class="accordion-collapse collapse" aria-labelledby="'flush-heading'+j" data-bs-parent="#accordionParent">
                            <div class="accordion-body">
                              <p>{{itemPara.parameter_id}}</p> 
                              <p>{{itemPara.parameter_name}}</p>
                            </div>
                          </div>
                        </div>
                         
                    </div>
                </div> -->
                <h5 >Parameters</h5>
                <ol class="width50">
                  <li *ngFor="let itemPara of LabTestParameter;let j = index;"> {{itemPara.parameter_name}}</li>
                </ol>


            </div>
            <div class="col-12 col-md-4">
                <div class="add_cart">
                    <ul class="gttl">
                      <li>Price : <span>₹ {{item.test_rate | number : '.2-2' }}</span></li>
                      <!-- <li>Save Price : <span class="badge bg-light text-dark">38%off</span></li> -->                      
                    </ul>
                    <!-- <a  data-toggle="modal" data-target=".cart-success" class="btn btn-primary w100 pa-10" (click)="add(i)">Add to Cart</a> --> 
                    <a data-toggle="modal" data-target=".cart-success" class="btn btn-primary-sm w100 pa-10" *ngIf="!isInCart(i)" (click)="add(i)" id="a{{i}}">Add to cart</a>
                    <a data-toggle="modal" data-target=".cart-success" class="btn btn-danger w100 pa-10"  style="display:none;" (click)="removeQ(i)" id="r{{i}}" >Remove from cart</a>

                </div>
                <div class="sample_report">
                    <h6 class="">Sample Report</h6>
                    <div class="sample">
                        <img src="assets/images/test-sample.jpg" style="width: 100%;">
                        <span class="cta" *ngIf="TagGroupProperties">
                            <a href="{{api.mediaURL}}{{TagGroupProperties}}" target="_blank"><i class="fa fa-eye"></i></a>
                            <a href="{{api.mediaURL}}{{TagGroupProperties}}" target="_blank"><i class="fa fa-download"></i></a>
                        </span>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</section> 